<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Jaime Alejandro Zapata Velásquez                              ###### -->
<!-- ###### @date: Abril 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML  :                                                     ###### -->
<!-- ###### la plantilla contiene los siguiente elementos que iteractuan entre si  ###### -->
<!-- ######                                                                        ###### -->                                                    ###### -->
<!-- ######       <v-row>                                                          ###### -->
<!-- ######          | <v-col>  (columna 1)              | <v-col>  (columna 2)    ###### -->
<!-- ######          |   header                          |                         ###### -->
<!-- ######          |      input buscar x código        |    visor de PDFs        ###### -->
<!-- ######          |      input buscar por nombre      |                         ###### -->
<!-- ######          |   tabla                           |                         ###### -->
<!-- ######          |   botón cargar carta agotado      |                         ###### -->
<!-- ######          |   dialog cargar agotado           |                         ###### -->
<!-- ######          |   notificacion diaria activar     |                         ###### -->
<!-- ######                                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <v-container fluidstyle="height: 81vh; margin-top: 20px">
        <v-layout>
            <!-- Inicio de tabla que muestra lista del servicio ListaCartasAgotado -->
            <div class="table-container" style="min-width: fit-content;">
                <v-data-table v-if="items.length > 0" :headers="tableHeaders" :items="items" 
                fixed-header :footer-props="footerProps" height="auto" class="elevation mt-4">
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr v-for="(item, index) in items" :key="index">
                                <td>{{ item.ips }}</td>
                                <td>{{ item.periodo }}</td>
                                <td> -- </td>
                            </tr>
                        </tbody>
                        </template>
                        <template v-slot:footer="{ pageStart, pageStop, itemsLength }">
                            {{ pageStart }}  {{ pageStop }}  {{ itemsLength }}
                        </template>                     
                </v-data-table>
            </div>
        </v-layout>
    </v-container>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
  import "jquery-ui/ui/widgets/resizable";
  //import $ from "jquery";
  //import pdf from 'vue-pdf'
  //import { base64Data } from './base64data.js';
  import { Role } from "../../../../router/role.js";

  export default{
    components: {
    },
    data() {
      return {
        name: 'Documents',
        dialog: false,  // abre modal en cargar carta de agotado por primera vez
        dialog2: false, // abre modal para cargar carta de agotado de nuevo
        tableHeaders: [
            { text: "IPS",                   value: "ips",     align: "left",  width: "33%", sortable: false },
            { text: "Periodo",               value: "periodo", align: "left",  width: "33%", sortable: false },
            { text: "Generar Archivo plano", value: "generar", align: "left",  width: "33%", sortable: false },
         ],
        items: [],
        searchText: '',
        footerProps:{
            'items-per-page-options': [7,15,20],
            'items-per-page-text': 'Items por página:',
            'show-current-page': true,
            'show-first-last-page':true,
        },
        itemsPerPage: 7,
        page: 1,
        totalPage: 2,
        codigoProducto: '',
        fechaReactivacion: '',
        cartaAgotadoFile: null,
        showSuccessMessage: false,
        showSuccessMessage2: false,
        codigoProductoBuscar : '',
        selectedPdf: '',
        pdfView: false,
        src: "",
        numPages: undefined,
        // para trazabilidad de un item
        itemSeleccionado: {},
        dialogoTrazabilidad: false,
        tablaTrazabilidad: {
            items: [],
            headers: [
                { text: "CAMPO", align: "left", width: "20%", sortable: false },
                { text: "VALOR ANTERIOR", align: "left", width: "20%", sortable: false },
                { text: "VALOR ACTUAL", align: "left", width: "20%", sortable: false },
                { text: "FECHA DE MODIFICACIÓN", align: "center", width: "25%", sortable: false },
                { text: "USUARIO", align: "center", width: "15%", sortable: false },
            ],
            footerProps: {
                'show-current-page': true,
                'show-first-last-page': true,
            },
            itemsPerPage: 5,
            page: 1,
        },
        nombresCampos: {
            nombre: 'Nombre',
            codigo: 'Código STONE',
            estado: 'Estado',
            fechareactivacion: 'Fecha reactivación'
        },
        dots : '', // la referencia a este elemento data dots en un método o en el html es this.dots
        showDots : false,
        // Opciones para visualizar Submenú
        verMenu: true,
        roles: Role,
        userRoles: {},
  
        months: [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ],
        selectedMonth: null,
        ipss: [
            'MEDICI', 'SALUD PLENA'
        ],
        selectedIps: null,
        allowMonthSelection: true,
        mostrarContenido1: true, // Inicialmente visible
        mostrarContenido: false, // Inicialmente oculto
      };
    },
    computed: {

    },

    methods: {
      initialize() {
        this.tableData = [
          { id: 1, medicamento: 'Paracetamol', laboratorio: 'Lab. A', estado: 'Activo', historial: 'Historial A'},
          { id: 2, medicamento: 'Ibuprofeno', laboratorio: 'Lab. B', estado: 'Activo', historial: 'Historial B'},
          { id: 3, medicamento: 'Amoxicilina', laboratorio: 'Lab. C', estado: 'Inactivo', historial: 'Historial C'},
          { id: 4, medicamento: 'Aspirina', laboratorio: 'Lab. D', estado: 'Activo', historial: 'Historial D' },
        ]
      },
      // método para obtener la lista de la malla autoinmune por periodo
      getListaDesdeController() {
        this.$http
            .get("msa-localapp/api/ListarMallaAutoinmunes")
            .then((result) => {
            console.log(result)
            this.items =  result.data; 
            console.log(this.items);
            })
            .catch((error) => {
            console.log(error.toJSON());
            });
       },
       // método para abrir en el visor de pdfs desde AWS la carta de agotado del item 
        cargarMalla() {
            // Crear un objeto FormData para empaquetar los datos del formulario
            const formData = new FormData();
            formData.append('ips_origen_malla', this.selectedIps);
            formData.append('period', this.selectedMonth);
            const file = this.$refs.mallaRef.files[0];
            if (this.$refs.mallaRef.files.length > 0) {
                formData.append('mallaFile', file);
            }
            console.log(formData)
            setTimeout(() => { this.dialog = false; }, 3000);
            //this.limpiarFormularioMalla();
            this.$http
                .post("msa-localapp/api/cargar_malla", formData, { headers: { 'Accept': 'application/json' } })
                .then((response) => { 
                    const blob = new Blob([response.data], { type: 'text/plain' });
                    const url = window.URL.createObjectURL(blob);
                
                    // Crea un enlace para descargar el archivo
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'logFile.txt');
                    document.body.appendChild(link);
                    link.click();

                    // Limpia la URL creada para el Blob
                    window.URL.revokeObjectURL(url);
                    this.showSuccessMessage = true;
                }) 
                .catch((error) => {
                    console.log(error?.response?.data);
                });
        },
        async checkPeriodValidity() {
            try {
                const formData = new FormData();
                formData.append('ips_origen_malla', this.selectedIps);
                formData.append('period', this.selectedMonth);
                this.$http
                .post("msa-localapp/api/validar_periodo", formData, { headers: { 'Accept': 'application/json' } })
                .then((response) => { 
                    if (response.data && response.data > 0) {
                        // Bloquear la selección del mes
                        this.allowMonthSelection = false;
                        alert('Ya has subido registros para este mes. Haz clic en OK para seleccionar otro mes.');
                        this.allowMonthSelection = true; // Desbloquear la selección del mes
                        this.selectedMonth = ''; // Reiniciar la selección del mes
                    } else {
                        // Permitir la selección del mes si no hay registros
                        this.allowMonthSelection = true;
                    }
                }) 
                .catch((error) => {
                    console.log(error?.response?.data);
                });
            } catch (error) {
                console.error('Error al llamar al servicio API:', error);
            }
        },

    }, // Fín methods

    mounted() {
        this.getListaDesdeController()
    },

    created() {
        this.initialize()
    }

  };
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
    .search-input {
        width: 100%;
        border-radius: 20px;
        border: 1px solid #ccc;
        padding: 10px;
        box-sizing: border-box;
    }
    ::v-deep .elevation div table thead tr th {
        background-color: rgb(223, 223, 223) !important;
    }

    ::v-deep .elevation div table thead tr th span {
        font-weight: bold;
        color: black !important;
    }
    .center-icon {
        text-align: center; /* Centrar horizontalmente */
        vertical-align: middle; /* Centrar verticalmente */
    }
    .blueGlobal {
    background-color: blue;
    /* Estilos adicionales para el encabezado */
  }

  /* estilos para el header que contiene input de busqueda y boton de cargar carta de agotado */
  .search-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    background-color: white;
  }

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.upload-message {
  margin-bottom: 10px;
}

.upload-btn {
  width: 300px;
background-color: green;

}

.divCard{
    margin-top: 5px;
}

.container {
  margin-top: -20px; /* Ajusta el valor según tus necesidades */
  overflow-x: hidden;
}

.boxPdf {
  overflow: auto;
  height: 100vh;
}

.boxPdf>span {
  width: 100%;
}

.input-file{
    padding: 21px;
    margin: 20p;
    background: aliceblue;
    margin-top: -22px;
    width: 100%;
}

.estado-button {
    cursor: pointer;
    background-color: #caced3;
    color: gray;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: normal;
    font-size: 11px;
}

.estado-button:hover {
  background-color: green;
  color: white
}

#period{
    width: 113px;
    padding: 5px;
    border: 1px solid #d6d5e0;
    border-radius: 7px;
    margin-left: 10px;
}

</style>
